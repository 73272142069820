<template>
    <div>
        <div class="popup">
            <div class="overlay"></div>
            <div class="modal-card">
                <div style="text-align:center" v-html="msg">            
                </div>
                <div v-if="type=='ing'">
                    <div class="spinner"></div>
                </div>
                <button v-if="type=='confirm'" class="btn" @click="$emit('close')">
                    confirm
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'msg', 'type'
    ],
    data() {
        return {
            
        }
    },
    
}
</script>
<style scoped>
.popup,
.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
}

.overlay {
    opacity: 0.5;
    background-color: black;
}
.modal-card {
    width: 400px;
    height: 400px;
    margin: auto;
    padding: 20px;
    background-color: white;
    z-index: 10;
    opacity: 1;
    border-radius: 5px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    color:black;
}
.modal-card div {
    font-size: 20px;
    word-break: keep-all;
}
.modal-card button {
    width: 90%;
    height: 50px;
    font-size: 20px;
    /*box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);*/
    border-radius: 5px;
}
.button_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    align-items: center;
}
button {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* z-index */
.story .popup {
    z-index: 99;
}
/* .class-modal.popup {
z-index: 99;
} */

.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
    .modal-card {
        width: 95%;
        height: unset;
        min-height: 186px;
    }
}
  </style>
  